import React  from 'react';


// Shared Components
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo"

// React
import { Disclosure } from '@headlessui/react'
// Icons
import { ChevronDownIcon,
    ClipboardListIcon,
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    LinkIcon,
    StatusOnlineIcon,
    ChartPieIcon,
    DeviceMobileIcon,
    UsersIcon, } from '@heroicons/react/outline'

// Images
import PricingImg from '../images/pricing/header@4x.png'


const faqs = [
    {
        question: "How do I know if my central station is compatible with Tech Tester?",
        answer:
            `Please take a look at our list of currently compatible central stations at alarmtechtester.com/compatible-central-stations.`,
    },
    {
        question: "What do I need to setup an account with Tech Tester?",
        answer:
            "All you need to get started with Tech Tester is a valid Central Station log-in with the appropriate permissions allowed. After you link your credentials to Tech Tester, you are all ready to get started!",
    },
    {
        question: "I use multiple central stations. Does Tech Tester allow that?",
        answer:
            "Absolutely. Tech Tester will allow you to link as many central stations to your account as you need.",
    },
    {
        question: "My free trial has expired, how do I continue to use Tech Tester?",
        answer:
            "To continue using Tech Tester, please enter your payment details within your account screen. If you need help with payments, please feel free to reach out to our support team, who will gladly assist in getting you back up and running with Tech Tester.",
    },
    {
        question: "When will my credit card be billed for Tech Tester?",
        answer:
            "Your credit card will be billed on the 1st of each month. If you joined late in the month, your credit card charge will reflect that. You will pay a pro-rated amount, only for the time you were using Tech Tester.",
    },
    {
        question: "I would like one of my technicians to have full access. Is that possible?",
        answer:
            "Absolutely. With Tech Tester, you are in full control over how much (or how little) access your technicians have. You can change the access levels at any time.",
    },
]

const features = [
    {
        name: 'Team Member Management',
        description: `Keep your team productivity 💯‎ ‎ with work queues and know what your team is doing and when.`,
        icon: UsersIcon,
    },
    {
        name: 'Work Queues',
        description: `Techs are assigned to accounts as they are needed. Update these on the go, and keep your tech's queues filled.`,
        icon: ClipboardListIcon,
    },
    {
        name: 'Team Messaging',
        description: 'Secure chat messaging between admin accounts and your tech accounts. Keep everything inside the app, no more texting potential sensitive data.',
        icon: ChatAltIcon,
    },
    {
        name: 'Direct Central Station Integration',
        description: 'Manage your Central Station credentials once and then forget about it! We securely store your credentials, so when you log in to Tech Tester, you are automatically connected to your Central Station.',
        icon: LinkIcon,
    },
    {
        name: 'Testing Utility',
        description: `Tech Tester's testing utility makes ensuring that an account has transmitted signals for all zones and even if those signals have restored easy. No more searching history screens for signals!`,
        icon: StatusOnlineIcon,
    },
    {
        name: 'Testing Reports',
        description: 'After you are done testing the system, send a report that show the status of each zone to your back office or directly to the subscriber - beautifully branded with your logo.',
        icon: DocumentReportIcon,
    },
    {
        name: 'Usage Metrics',
        description: 'Automated reports emailed right to you show team usage metrics. This lets you know who is accessing what and when, all right to your inbox. ',
        icon: ChartPieIcon,
    },
    {
        name: 'Tech Tester Mobile',
        description: `Our native mobile application makes you and you tech's lives easier by giving you all the power of Tech Tester right in your iOS or Android device.`,
        icon: DeviceMobileIcon,
    },
    {
        name: 'Support When You Need It',
        description: `Our support team is ready to assist you with any questions you have. Feel free to reach out whenever you have a question!`,
        icon: HeartIcon,
    },
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Pricing() {

    return (
        <div>
            <Seo title="Pricing" description="Learn about the Tech Tester pricing model and its features." />
            <Header/>
            {/* Hero section */}
            <div className="bg-navy py-24">
                <div className={"pt-10 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"}>
                    <div className={"my-auto text-left "}>
                        <h1 className={"md:text-5xl text-4xl text-white font-bold"}>Pricing</h1>
                        <h3 className={"text-white text-lg pt-10"}>Tech Tester is a software as a service (SAAS) and subscriptions are based on a per user/per month cost. You will be billed monthly for your active users using the on-file credit card. All transactions are processed securely with Stripe, we do not store any of your payment data.</h3>
                        <a href={'/contact'}>
                            <button  className="mt-10 inline-flex px-4 py-2 text-base border border-transparent rounded-md shadow-sm text-sm text-black font-bold bg-yellowTT hover:bg-yellowTT-alt"
                            >START FREE TRIAL</button></a>
                    </div>
                    <div className={"m-auto"}>
                        <img
                            className=""
                            src={PricingImg}
                            alt="Temp Item"
                        />
                    </div>
                </div>
            </div>

            {/* Alternating Feature Sections */}
            <div className="relative overflow-hidden">
                {/* Pricing Section */}
                <div className={'my-48'}>
                    <h1 className={"text-center relative z-10 font-bold text-3xl"}>Our pricing model is a simple, per user, per month subscription.</h1>
                    <h1 className={"text-center relative z-10 font-bold text-3xl pb-12"}>Cancel at any time.</h1>
                    <h1 className={'text-center text-xl'}>Have a larger team? <span className={'underline'}><a href={'/contact'} className={''}>Contact us</a></span> for special pricing options.</h1>
                </div>



                <div className="bg-myGray-300">
                    <div className="max-w-4xl mx-auto px-12 py-16 sm:px-12 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
                        <h2 className="text-3xl text-center font-extrabold text-black tracking-tight">Your monthly plan gets you:</h2>

                        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                            {features.map((feature) => (
                                <div key={feature.name}>

                                    <div className="mt-6">
                                        <div className={"flex items-center justify-left"}>
                                            <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                                                <feature.icon className="h-6 w-6 text-navy" aria-hidden="true" />
                                            </span>
                                            <h3 className="text-lg font-medium text-black">{feature.name}</h3>
                                        </div>
                                        <p className="mt-2 text-base text-myGray-500">{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* FAQ Section */}

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto">
                            <h2 className="text-center text-3xl font-extrabold text-gray-900">FAQ's</h2>
                            {faqs.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6 bg-myGray-300 rounded-md m-5 p-5">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                          />
                        </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base text-gray-500">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}